if(use_vue_js === true){
	require('./file-thumb');
	Vue.component('media-library', {
		props:[],
		template: `
			 <div>
                <div class="the_media_library_holder">
                    <h1>Media Library</h1>
                    <p>Please select a file(s) to add to the post</p>
                    <hr />
                    <p>{{selectedFiles.length}} selected from {{inputs.uploads_remaining}} available</p>
                    <div v-for="(file, key) in mediaFiles" @click="toggleSelectThumb(file, key)" class="file_container media_library_image" :class="{'selected_file_to_add': file.isSelected}">
	                    <file-thumb :file="file"></file-thumb>
	                </div>
                    <div class="clear"></div>
            		<div class="media_library_options">
            			<div class="hide btn btn-info">Show All</div>
            			<div class="hide btn btn-success">Show Documents</div>
            			<div class="float_right">
            				<div v-show="selectedFiles.length > 0" @click="removeSelectedFiles" class="btn btn-warning cancel_currently_selectedFiles">Cancel</div>
            				<div v-show="selectedFiles.length > 0" @click="addFilesToPost" class="btn btn-success add_files_to_post">Add Files To Post</div>
            				<div @click="hideMediaLibrary" class="btn btn-danger close_the_media_library">X</div>
            			</div>
            			<div class="clear"></div>
            		</div>
                </div>
            </div>
		`,
		mounted(){
			$('.the_media_library_holder').height(($(window).height() -100));
		},
		created:function(){
			this.inputs = inputs;
			 var $this = this;

	        $.ajax({
	            url:'/api/uploads',
	            success:function(files){
	                var filtered = [];
	                files.map(function(selectedFile){
	                	Vue.set (selectedFile, 'isSelected', false);
	                    if($this.isntAlreadyInPost(selectedFile) == true){
	                        filtered.push(selectedFile);
	                    }
	                });
	                $this.mediaFiles = filtered;
	            }
	        });
		},
		computed:{
			selectedFileNames:function(){
				var names = [];
				this.selectedFiles.forEach(function(file){
					names.push(file.name);
				});
				return names;
			}
		},
		methods:{
			hideMediaLibrary(){
				this.$emit('hide');
			},
			isntAlreadyInPost(selectedFile){
				var found = true;
				if(typeof this.inputs.posted_files != 'string'){
			        this.inputs.posted_files.map(function(file){
			        	if(selectedFile.id == file.id){
			                found = false;
			            }
			        });
		    	}
		        return found;
			},
			addFilesToPost(){
				this.$emit('added', {
            		files:this.selectedFileNames
            	});
			},
			toggleSelectThumb(file, key){
				if(this.inputs.uploads_remaining > this.selectedFiles.length){
					file.isSelected = !file.isSelected;
					if(file.isSelected === true){
						this.selectedFiles.push(file);
					}else{
						var filtered = [];
						this.selectedFiles = this.selectedFiles.filter(function(selectedFile){
							return (file.id != selectedFile.id);
						});
						//this.selectedFiles = filtered;
					}
				}else{
					if(file.isSelected === true){
						file.isSelected = false;
						var filtered = [];
						this.selectedFiles = this.selectedFiles.filter(function(selectedFile){
							return (file.id != selectedFile.id);
						});
					}else{
						swal({
		                    title: "Error!!",
		                    text: "You have selected the macimum number of files available. Please deselect another image!",
		                    type: "error",
		                    timer:15000,
		                    showConfirmButton: true
		                });
					}
				}
			},
			removeSelectedFiles:function(){
				this.mediaFiles.map(function(file){
					file.isSelected = false;
				});
		        this.selectedFiles = [];
		    },
		},
		data:function(){
			return {
				mediaFiles:[],
				selectedFiles: [],
				inputs:{}
			}
		}
	});
}


