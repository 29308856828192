/*NEW CMS FUNCTIONALITY FROM RESKIN*/
$('.module_list_name').on('click', function(){
	var $this = $(this);
	$this.next('ul').addClass('vis_subnav').slideToggle(300);
});
$('.cms_section_heading').on('click', function(){
	var $this = $(this);
	$this.next('ul').addClass('vis_section').slideToggle(300);
});
$('.cms_nav_toggle').on('click', function(){
	$('.responsive_nav_collumn').toggleClass('reponsive_nav_mode');
});
$('.toggleable_list').on('change', function(){
    $('.toggleable_checkbox').prop('checked', (this.checked) ? true : false);
});

/*Navigation Form*/
$('.navigation_type_select').on('change', function(){
	var menu_type = $(this).attr('data-menuname');
	var tier = $(this).attr('data-tier');
	var parent_id = $(this).attr('data-parentId');
	var option = $(this).val();
	$('.navigation_selections').show();
	$('.filtered_nav_choices').hide();

	switch(option) {
	    case 'none':
	    	$('.navigation_selections').hide();
	    break;

	    case 'custom':
	    	$('.nav_custom_selection').show();
	    break;

	    case 'post_list':
	    case 'module_heading':
	    	$('.nav_module_selection').show();
	    break;

	    case 'single_post':
	    	$('.nav_module_selection').show();
	    	$('.nav_post_selection').show();
	    break;

	    default:
	    	$('.nav_custom_selection').show();
	    break;
	}
	$('.module_post_selection').on('change', function(){
		$('.module_posts').addClass('all_posts_list');
		$('.all_post_selects').removeAttr('name');
		var module_option = $(this).val();
		$('.post_list_' + module_option).removeClass('all_posts_list');
		$('.post_select_' + module_option).attr('name', 'post_id');
	});

});
