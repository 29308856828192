$('.batch_delete_records').on('click', function(e){
	e.preventDefault();
	swal({   title: "Confirm Batch Delete",
	  text: "Do you want to delete these records?",
	  type: "info",
	  showCancelButton: true,
	  closeOnConfirm: false,
	  showLoaderOnConfirm: true,
	},
	function(){
		var checkedVals = $('.batch_delete_checkbox:checkbox:checked').map(function() {
			return this.value;
		}).get();
		$('.batch_delete_string').val(checkedVals);
		$('.batch_delete_records_form').submit();
	});
});

$('.toggleable_checkbox').on('click', function(){
	if($('.batch_delete_checkbox:checkbox:checked').length == 0){
		$('.batch_delete_records').hide();
	}else{
		$('.batch_delete_records').show();
	}
});

$('.toggleable_list').on('change', function(){
	if($(this).is(':checked')){
		$('.batch_delete_records').show();
	}else{
		$('.batch_delete_records').hide();
	}
});
