var global = require('./global_functions');
/*Post Visibility Ajax Functionality*/
$('.change_visibility_button').on('click', function(e){
	e.preventDefault();
	var button = $(this);
	swal({   title: "Confirm Visibility Change",
	  text: "Do you want to change the visibility for this post?",
	  type: "info",
	  showCancelButton: true,
	  closeOnConfirm: false,
	  showLoaderOnConfirm: true,
	},
	function(){

		var updateTo 		= button.attr('data-updateTo');
		var module 			= button.attr('data-module');
		var postId 			= button.attr('data-postId');
		var moduleId 		= button.attr('data-moduleId');

		var Vis_change = Visibility.change(updateTo, module, postId, moduleId);
		Vis_change.done(function(response){
			if(response.response == 'success'){
				button.attr('title', response.link_title);
				button.attr('data-updateTo', response.change_to);
				button.find('.glyphicon').toggleClass('glyphicon-remove').toggleClass('glyphicon-ok');
				if(response.change_to == 0){
					var heading = 'Not Visible';
				}else{
					var heading = 'Visible';
				}
				$('.vis_header').text(heading);
				global.show_alert('success', response.message);
			}else{
				global.show_alert('warning', response.message);
			}

		}).fail(function(error){
			global.show_alert('warning', 'Visibility Not Updated. Please contact the administrator');
		});
	});
});

var Visibility = {
	change: function(updateTo, module, postId, moduleId){
		var promise = $.Deferred();
		$.ajax({
			type: 'POST',
			url: '/cms_post_visibility_ajax',
			data:{
				_token: _token,
				visible: updateTo,
				module: module,
				moduleId: moduleId,
				id:postId
			},
			success:function(data){
				var parsed = $.parseJSON(data);
				promise.resolve(parsed)
			},
			error:function(error){
				promise.reject(error);
			}
		});
		return promise;
	}
}
