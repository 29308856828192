if(use_vue_js === true){
	Vue.component('file-thumb', {
		props:['file'],
		template:`
			<div>
				<div class="file_information">
                    <p class="document_name">{{file.name}}</p>
                    <p>{{fileData(file).insideDiv}}px</p>
                    <p>{{file.size_in_bytes}}</p>
                    <p><a class="preview_the_file" target="_blank" href="'/uploads/' + file.full_path">Preview File</a></p>
                </div>
            	<img class="image_centered_in_div lazy" :src="'/uploads/' + fileData(file).useImage" style="max-width:200px;max-height:200px"/>
				
			</div>
		`,
		computed:{
			
		},
		methods:{
			fileData(file){
				if(file.file_is_image){
		            var useImage = file.full_path;
		            var insideDiv = file.image_width + " x " + file.image_height;
		        }else{
		            var useImage = "/images/file.png";
		            var insideDiv;
		        }
				return {
					useImage: useImage,
					insideDiv: insideDiv
				}
			}
		},
		created:function(){
			this.inputs = inputs;
		},
		data:function(){
			return {
				
			}
		}
	});
}