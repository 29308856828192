if(use_vue_js === true){
	require('./radio-box');
	require('./checkbox');
	require('./caption-box');

	Vue.component('file-metas', {
		props:['file'],
		template: `
			<div :class="'cms_file_meta_container text_left meta_container_' + inputs.name">
                <hr />
                <div v-for="(value, field) in inputs.meta">
                	<div v-if="value.type == 'radio'">
                		<radio-box :field="field" :file="file"></radio-box>
                	</div>

                	<div v-if="value.type == 'caption'">
                		<caption-box :field="field" :file="file"></caption-box>
                	</div>

                	<div v-if="value.type == 'checkbox'">
                		<checkbox :field="field" :file="file"></checkbox>
                	</div>
				  
				 </div>
            </div>
		`,
		created:function(){
			this.inputs = inputs;
		},
		computed:{

		},
		methods:{},
		data:function(){
			return {
				
			}
		}
	});
}


