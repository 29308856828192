var global = require('./global_functions');
require('./tinymce');
require('./batch_post_delete');
require('./cms_navigation');
require('./cms_post_visibility');
//require('./file_uploads');
require('./vue_components/uploaded-files');

$('.reply_to_email').on('click', function(){
	var id = $(this).attr('data-id');
	$.ajax({
		type: 'POST',
		url: '/respond_to_enquiry',
		data:{
			_token: _token,
			id: id
		},
		success:function(data){
			var parsed = $.parseJSON(data);
			if(parsed == 'success'){
				global.display_ordering_success();
			}else{
				global.display_ordering_error();
			}
		}
	});
});

/*All display ordered tables will use this*/
$('.sortable_items').sortable({
	update:function(event, ui){
		var posted_data = $(this).sortable('toArray');
		var table 		= $(this).attr('data-table');
		var data_array = {
			order:posted_data,
			table: table,
			_token: _token
		};
		$.ajax({
			type: 'POST',
			url: '/cms_module_display_ordering',
			data:data_array,
			success:function(data){
				var parsed = $.parseJSON(data);
				if(parsed == 'success'){
					global.display_ordering_success();
				}else{
					global.display_ordering_error();
				}
			}
		});
	}
});
/*All display ordered tables will use this*/
$('.sortable_nav_items').sortable({
	update:function(event, ui){
		var posted_data = $(this).sortable('toArray');
		var table 		= $(this).attr('data-table');
		var data_array = {
			order:posted_data,
			table: table,
			_token: _token
		};
		$.ajax({
			type: 'POST',
			url: '/cms_navigation_display_ordering',
			data:data_array,
			success:function(data){
				var parsed = $.parseJSON(data);
				if(parsed == 'success'){
					global.display_ordering_success();
				}else{
					global.display_ordering_error();
				}
			}
		});
	}
});

/*CMS Search Facility*/
$('#cms_searchbar').keyup(function(e){
	var filter_rows = $('.module_record_item');
	var currently_typed = $(this).val().toLowerCase();
	filter_rows.hide();
	if(currently_typed == ''){
		filter_rows.closest('li').show();
		filter_rows.show();
	}else{
		var filter_count = 0;
		filter_rows.each(function(){
			$(this).closest('li').hide();
			var search_field = $(this).children('td').text().toLowerCase();
			if(search_field.indexOf(currently_typed) != -1){
				filter_count++;
				$(this).closest('.module_record_item').show();
				$(this).closest('li').show();
			}
		});
	}
});

$('#tweet').keyup(function(e){
	var currently_typed = $(this).val().toLowerCase();
	if(currently_typed == ''){
        $('#post_tweet').attr('disabled', true);
	}else{
		$('#post_tweet').attr('disabled', false);
	}
});

$("#datepicker").datepicker({
    dateFormat: 'dd/mm/yy'
});