var global = require('./../global_functions');
console.log(global);
if(use_vue_js === true){
	require('./upload-instructions');
	require('./dropzone');
	require('./file-thumbs');
	require('./media-library');

	window.VueEvent = new Vue();
	Vue.component('uploaded-files', {
		template: `
		<div class="white">
			<hr />
			<label>{{fileLabel}}</label>
			<br />
			<upload-instructions></upload-instructions>
			<div v-if="inputs.uploads_remaining < 1">
				<p :class="topCollumnWidth">You have uploaded the maximum amount of files allowed. Please delete some old ones before adding new ones.</p>
			</div>
			<div class="" v-else>
				<dropzone :style-class="dropzone" v-on:appended="newUpload"></dropzone>
			</div>
			<div class="clear"></div>
			<div class="current_files_in_post">
				<div :class="deleteContainerClass">
	                <file-thumbs :thumbs="inputs.posted_files" v-on:fileDeletesUpdated="fileDeletesUpdated" v-on:sorted="updateOrder" :showMetas="showMetas" :deleteMode="deleteMode"></file-thumbs>
	                
	                <div class="file_toolbar">
						<div @click="handleDeleteFiles" v-if="files.length > 0" :class="'toolbar_button btn btn-danger delete_files_button delete_files_' + inputs.name">
							{{deleteButtonText}}
						</div>
						<div @click="CancelDeleteFiles" v-show="deleteMode === true" :class="'toolbar_button cancel_delete_files cancel_delete_files_' + inputs.name">
							Cancel Delete Files
						</div>
						<div v-if="inputs.meta !== undefined" v-show="deleteMode === false"  @click="showMetaContainers" :class="'toolbar_button btn btn-success edit_meta_button show_meta_container_' + inputs.name">Edit Meta</div>
						<div v-if="inputs.uploads_remaining > 0 && inputs.media_library_files !== undefined" v-show="deleteMode === false"  @click="showMediaLibraryDiv" :class="'toolbar_button float_right btn btn-info show_media_library show_media_library_' + inputs.name">Media Library</div>
						<div class="main_media_library_container">
							<media-library v-on:hide="showMediaLibrary = false" v-on:added="newUpload" v-if="inputs.uploads_remaining > 0 && inputs.media_library_files !== undefined" v-show="showMediaLibrary"></media-library>
						</div>
					</div>
				</div>
            </div>
			<input v-model="inputValue" type="text" style="color:black; display:none; width:100%" :class="'file_slugs_' + inputs.name" :name="inputs.name">
			<div class="clear"></div>
		</div>
		`,
		/*
		<div v-if="inputs.uploads_remaining > 0 && inputs.media_library_files !== undefined">
			<div @click="showMediaLibrary" :class"'toolbar_button float_right btn btn-info show_media_library show_media_library_' + inputs.name">Media Library</div>
		</div>
		if(this.props.inputs.uploads_remaining && this.props.inputs.media_library_files){
			media_library_button = <div onClick={this.showMediaLibrary} data-fieldname={name} className={"toolbar_button float_right btn btn-info show_media_library show_media_library_" + name}>Media Library</div>
			media_library = <MediaLibrary files={this.state.files} addFilesToPost={this.addFilesToPost} inputs={this.props.inputs} />
		}
		*/
		computed:{
			fileLabel(){
				if(inputs.label !== undefined){
					return this.upperCaseWords(inputs.label);
				}
				return this.upperCaseWords(inputs.name);
			},
			topCollumnWidth(){
				if(this.inputs.instructions !== undefined || this.inputs.meta_instructions !== undefined){
					return 'small_dropzone col-sm-4';
				}else{
					return 'col-sm-12 full_dropzone';
				}
			},
			dropzone(){
				return this.topCollumnWidth + " dropzone";
			},
			inputValue(){
				return this.files.join(',');
			},
			deleteContainerClass:function(){
				if(this.deleteMode === false){
					return '';
				}else{
					return 'delete_container';
				}
			},
			deleteButtonText(){
				if(this.deleteMode === false){
					return 'Delete Files';
				}else{
					return 'Confirm Deletions';
				}
			}
		},

		methods:{
			newUpload(items){
				var $this = this;
				var fileCache = $this.files;
				items.files.forEach(function(file){
					$this.files.push(file);
				});
				swal({
				  title: "Files Uploaded",
				  text: "Save Now?",
				  type: "success",
				  showCancelButton: true,
				  closeOnConfirm: false,
				  showLoaderOnConfirm: true,
				},
				function(confirmed){
					if(confirmed){
						$('.cms_main_form').submit();
					}else{
						$this.files = fileCache;
					}
				  
				});
			},
			updateOrder(items){
				this.files = items.files;
				var data_array = {
					field_name: this.inputs.name,
					order: this.files.join(","),
					table: this.inputs.module_slug,
					id: this.inputs.id,
					_token : _token
				};

				$.ajax({
					type: 'POST',
					url: '/file_ordering',
					data:data_array,
					success:function(data){
						var parsed = $.parseJSON(data);
						if(parsed == 'success'){
							global.display_ordering_success();
						}else{
							global.display_ordering_error();
						}
					}
				});
			},
			fileDeletesUpdated(items){
				this.selectedDeletes = items.files;
			},
			handleDeleteFiles(){
				var $this = this;
				if(this.deleteMode === true){
					if($this.selectedDeletes.length == 0){
						swal({
		                    title: "Error!!",
		                    text: "No files were selected for deletion!",
		                    type: "error",
		                    timer:15000,
		                    showConfirmButton: true
		                });
					}else{
						var updated = [];
						var fileCache = $this.files;
						this.files.map(function(file){
							if($this.selectedDeletes.indexOf(file) == -1){
								updated.push(file);
							}
						});
						$this.files = updated;
						/*If we are getting issues with deletes not happening we need to wait
						for the above map to finish*/
						swal({
						  title: "Confirm Delete?",
						  text: "Save Now?",
						  type: "success",
						  showCancelButton: true,
						  closeOnConfirm: false,
						  showLoaderOnConfirm: true,
						},
						function(isConfirm){
							if(isConfirm){
								$this.deleteMode = false;
								$this.inputs.posted_files = $this.inputs.posted_files.filter(function(file){
									return (updated.indexOf(file.name) != -1);
								});
							  	$('.cms_main_form').submit();
							}else{
								$this.files = fileCache;
							}
						});
					}
					
				}else{
					this.deleteMode = true;
				}
			},
			CancelDeleteFiles(){
				this.deleteMode = false;
				this.selectedDeletes = [];
			},
			showMetaContainers(){
				this.showMetas = !this.showMetas;
			},
			upperCaseWords:function(str){
				return str.toLowerCase().replace('_', ' ').replace(/\w\S*/g, function(txt){
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				});
			},
			showMediaLibraryDiv(){
				this.showMediaLibrary = true;
			}
		},
		watch:{
			showMediaLibrary:function(val){
				if(val === true){
					$('.main_media_library_container').fadeIn();
				}else{
					$('.main_media_library_container').fadeOut();
				}
			}
		},
		created:function(){
			this.inputs = inputs;
			if(inputs.value != ''){
				this.files = inputs.value.split(',');
			}
		},
		data:function(){
			return{
				inputs:{},
				inputValue:'',
				files:[],
				selectedDeletes:[],
				deleteMode:false,
				showMetas:false,
				showMediaLibrary:false
			}
		}
	});

	var VueUploader = new Vue({
		el:'#CMSUploadedFilesVueContainer'
	});
}


