if(use_vue_js === true){
	Vue.component('dropzone', {
		props:['styleClass'],
		template: `
			<div>
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/dropzone/4.0.1/dropzone.css">
				<div id="addPhotosForm" action="" :class="styleClass" style="color:#242429!important; background:white"></div>
				<textarea v-model="jsonValues" style="color:black; display:none" :name="'file_json_' + inputs.name" cols="50" rows="10"></textarea>
			</div>
		`,
		computed:{
			jsonValues(){
				return "[" + this.jsonFileDatas.join(',') + "]";
			}
		},
		methods:{},
		
		created:function(){
			this.inputs = inputs;
			var $this = this;
			var name                    = this.inputs.name;
	        var uploads_remaining       = this.inputs.uploads_remaining;
	        var file_extensions_allowed = this.inputs.file_extensions_allowed;
	       	var files = [];
	        var uploaded_json = [];
	        Dropzone.options.addPhotosForm = {
	            url: "/cms_upload_handler",
	            paramName: "file",
	            maxFilesize: 100,
	            maxFiles: uploads_remaining,
	            acceptedFiles: file_extensions_allowed,
	            dictDefaultMessage:"Click or drop files here to upload (" + uploads_remaining + " remaining)",
	            maxfilesexceeded:function(file){
	                swal({
	                    title: "Error!!",
	                    text: "You can only upload " + uploads_remaining + " files! The additionals have been removed!",
	                    type: "error",
	                    timer:15000,
	                    showConfirmButton: true
	                });
	            },
	            sending: function(file, xhr, formData) {
	                formData.append("_token", _token);
	            },
	            success:function(file, response){
	                $this.jsonFileDatas.push(response);
	                var parsed = $.parseJSON(response);
	                files.push(parsed.name);
	            },
	            error:function(file, message){
	                if(file != null){
	                    this.removeFile(file);
	                }
	            },
	            queuecomplete:function(){
	            	$this.$emit('appended', {
	            		files:files
	            	});
	                //$("body").prepend("<div class=\'show_loading_gif\'><img src=\'/images/ajax-loader.gif\'><div class=\'saving_progress\'>SAVING THE FORM....</div></div>");

	                 // setTimeout(function(){
	                 //     $(".cms_main_form").submit();
	                 // }, 800);
	            }
	        }
		},
		data:function(){
			return{
				jsonFileDatas:[]
			}
		}
	});
}


