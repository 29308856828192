module.exports = {
	show_alert(type, message){
		swal({
			title: (type == 'success') ? 'Success!!': 'Error!!',
			text: message,
			type: type,
			timer:1500,
			showConfirmButton: false
		});
	},
	show_alert_append(type, message){
		if(type == 'success'){
			var bg = '#63CB37';
		}else{
			var bg = '#b5192f';
		}
		$('body').prepend(''
		+ '<div class="alert-'+ type +' alert alert-dismissible page_alert" style="background:'+ bg + ';color:white;position:fixed;top:0px;width:100%;text-align:center;display:none;z-index:10001!important">'
		+ message
		+ '</div>');

		$('.page_alert').slideDown(300, function(){
			$(this).delay(2000).slideUp(300, function(){
				$(this).remove();
			});
		});
	},
	display_ordering_success(){
		this.show_alert('success', 'Order changed Successfully');
	},
	display_ordering_error(){
		this.show_alert('warning', 'Order not changed. Please try again or contact the website administrator');
	}
};
