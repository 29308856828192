if(use_vue_js === true){
	Vue.component('upload-instructions', {
		template: `
		<div class="instructions file_instruction_list col-sm-8">
            <ul>
            	<li>{{inputs.title}}</li>
            	<li v-for="instruction in inputs.instructions" v-html="instruction"></li>
            </ul>
		</div>
		`,
		created:function(){
			this.inputs = inputs;
		}
	});
}


