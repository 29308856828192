if(use_vue_js === true){
	Vue.component('radio-box', {
		props:['field', 'file'],
		template:`
			<div>
				<input :checked="checked" type="radio" :id="computedId" :name="computedName" :value="file.id" /> 
				<label :for="computedId" class="white">{{labelName}}</label>
			</div>
		`,
		computed:{
			computedName:function(){
				return "file_metas_" + this.inputs.name + "[" + this.field + "]";
			},
			computedId:function(){
				return 'label_' + this.field + '_' + this.file.id;
			},
			labelName:function(){
				return this.toTitleCase(this.field.replace('_', ' '));
			},
			checked:function(){
				var $this = this;
				var file = this.file;
				var found = false;
		        if(this.inputs.meta_values !== undefined){
		            this.inputs.meta_values.forEach(function(value){
		        		if(file.id == value.upload_id && $this.field == value.meta_field){
		        			found = true;
		                }
		            });
		        }
				return found;
			}
		},
		methods:{
			toTitleCase:function(str)
		    {
		        return str.replace(/\w\S*/g, function(txt){
		        	return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		        });
		    }
		},
		created:function(){
			this.inputs = inputs;
		},
		data:function(){
			return {
				
			}
		}
	});
}