if(use_vue_js === true){
	require('./file-metas');
	Vue.component('file-thumbs', {
		props:['deleteMode', 'showMetas'],
		template: `
			<ul :class="'sorted_files sorted_images_' + inputs.name">
				<li v-for="file in inputs.posted_files" :id="file.name" class="file_container" @click="toggleDeleteImage(file)">
	            	<file-metas :file="file" v-if="inputs.meta !== undefined" v-show="showMetas"></file-metas>
	            	<div class="file_information">
	                    <p class="document_name">{{file.name}}</p>
	                    <p>{{fileData(file).insideDiv}}px</p>
	                    <p>{{file.size_in_bytes}}</p>
	                    <p><a class="preview_the_file" target="_blank" :href="'/uploads/' + file.full_path">Preview File</a></p>
	                </div>
	                <img v-if="file.file_is_image" class="image_centered_in_div lazy" :src="'/uploads/' + fileData(file).useImage" style="max-width:200px;max-height:200px"/>
	            	<img v-if="!file.file_is_image" class="image_centered_in_div lazy" :src="'/images/file.png'" style="max-width:200px;max-height:200px"/>
					<img v-show="file.deleteDisplayToggle" :class="'delete_post_file_overlay delete_file_' + file.id" src="/images/selected_delete.png" style="width:221px;height:221px" />
				</li>
			</ul>
		`,
		watch:{
			deleteMode(val){
				if(val === false){
					this.selectedDeletes = [];
					this.inputs.posted_files.forEach(function(returnedFile){
						returnedFile.deleteDisplayToggle = false;
					});
				}
			}
		},
		computed:{},
		methods:{
			fileData(file){
				if(file.file_is_image){
		            var useImage = file.full_path;
		            var insideDiv = file.image_width + " x " + file.image_height;
		        }else{
		            var useImage = "/images/file.png";
		            var insideDiv;
		        }
				return {
					useImage: useImage,
					insideDiv: insideDiv
				}
			},
			toggleDeleteImage(file){
				var $this = this;
				if(this.deleteMode === true){
					file.deleteDisplayToggle = !file.deleteDisplayToggle;
					$this.selectedDeletes = [];
					this.inputs.posted_files.forEach(function(returnedFile){
						if(returnedFile.deleteDisplayToggle === true){
							$this.selectedDeletes.push(returnedFile.name);
						}
					});

					$this.$emit('fileDeletesUpdated', {
						files:$this.selectedDeletes
					});
				}

			},
		},
		mounted:function(){
			var $this = this;
			$('.sorted_files').sortable({
				update:function(event, ui){
					$this.$emit('sorted', {
						files:$(this).sortable('toArray')
					});					
				}
			});
		},		
		created:function(){
			var $this = this;
			this.inputs = inputs;
			if(typeof this.inputs.posted_files != 'string'){
				this.inputs.posted_files.map(function(file, key){
					Vue.set (file, 'deleteDisplayToggle', false);
				});
			}
			var $this = this;
		},
		data:function(){
			return{
				inputs:{},
				selectedDeletes:[]
			}
		}
	});
}
