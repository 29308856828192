tinyMCE.PluginManager.add('stylebuttons', function(editor, url) {
  ['h1', 'h2', 'h3', 'h4'].forEach(function(name){
   editor.addButton("style-" + name, {
       tooltip: "Toggle " + name,
         text: name.toUpperCase(),
         onClick: function() { editor.execCommand('mceToggleFormat', false, name); },
         onPostRender: function() {
             var self = this, setup = function() {
                 editor.formatter.formatChanged(name, function(state) {
                     self.active(state);
                 });
             };
             editor.formatter ? setup() : editor.on('init', setup);
         }
     })
  });
});

tinyMCE.init({
	plugins: "code, link, paste, wordcount, advlist, lists advlist, table",
	paste_preprocess : function(pl, o) {
	  o.content = strip_tags( o.content,'p,b,i' );
	},
	style_formats : additional_tinymce_formats().concat([
        {title : 'Proxima Thin', inline : 'span', classes : 'prox_thin'},
        {title : 'Proxima Light', inline : 'span', classes : 'prox_light'},
        {title : 'Proxima Semi', inline : 'span', classes : 'prox_semi'},
        {title : 'Proxima Bold', inline : 'span', classes : 'prox_bold'},
        {title : 'Para 12px', inline : 'span', classes : 'wysiwyg_12px'},
        {title : 'Para 14px', inline : 'span', classes : 'wysiwyg_14px'},
        {title : 'Para 16px', inline : 'span', classes : 'wysiwyg_16px'},
        {title : 'Para 18px', inline : 'span', classes : 'wysiwyg_18px'},
        {title : 'Para 20px', inline : 'span', classes : 'wysiwyg_20px'},
        {title : 'Para 22px', inline : 'span', classes : 'wysiwyg_22px'},
        {title : 'Para 24px', inline : 'span', classes : 'wysiwyg_24px'},
        {title : 'Para 26px', inline : 'span', classes : 'wysiwyg_26px'},
        {title : 'Para 28px', inline : 'span', classes : 'wysiwyg_28px'},
        {title : 'Para 30px', inline : 'span', classes : 'wysiwyg_30px'},
        {title : 'Para 32px', inline : 'span', classes : 'wysiwyg_32px'},
        {title : 'Para 36px', inline : 'span', classes : 'wysiwyg_36px'},
        {title : 'Para 40px', inline : 'span', classes : 'wysiwyg_40px'},
        {title : 'Para 48px', inline : 'span', classes : 'wysiwyg_48px'},
        {title : 'Para 54px', inline : 'span', classes : 'wysiwyg_54px'},
        {title : 'Para 60px', inline : 'span', classes : 'wysiwyg_60px'},
        {title : 'Para 80px', inline : 'span', classes : 'wysiwyg_80px'}
	]),
	content_css: "/css/cms_wysiwyg.css", //font styles will be picked up from in here
	menubar:false,
	//Keeps Paste Text feature active until user deselects the Paste as Text button
	paste_auto_cleanup_on_paste : true,
	paste_remove_styles: true,
	paste_remove_styles_if_webkit: true,
	paste_strip_class_attributes: true,
    paste_text_linebreaktype:true,
	toolbar1: "p h1 h2 h3 h4 | styleselect | undo redo | bold italic superscript | table link bullist code | alignleft aligncenter alignright alignjustify",
	selector:'.cms_textarea',
	extended_valid_elements : "a[href|target=_blank]",
	removeformat : [
	    {selector : 'b,strong,em,i,font,u,strike', remove : 'all', split : true, expand : false, block_expand : true, deep : true},
	    {selector : 'span', attributes : ['style', 'class'], remove : 'empty', split : true, expand : false, deep : true},
	    {selector : '*', attributes : ['style', 'class'], split : false, expand : false, deep : true}
	],
});

function additional_tinymce_formats(){
	if (typeof set_additional_tinymce_formats == 'function') {
  		return set_additional_tinymce_formats();
	}else{
		return [];
	}
}

/*Below function is called when the textarea gets pasted into to get rid of unwanted characters*/
	function strip_tags (str, allowed_tags){
	    var key = '', allowed = false;
	    var matches = [];
	    var allowed_array = [];
	    var allowed_tag = '';
	    var i = 0;
	    var k = '';
	    var html = '';
	    var replacer = function (search, replace, str) {
	        return str.split(search).join(replace);
	    };
	    /* Build allowes tags associative array*/
	    if (allowed_tags) {
	        allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
	    }
	    str += '';

	    /* Match tags*/
	    matches = str.match(/(<\/?[\S][^>]*>)/gi);
	    /* Go through all HTML tags*/
	    for (key in matches) {
	        if (isNaN(key)) {
	                /* IE7 Hack*/
	            continue;
	        }

	        /* Save HTML tag*/
	        html = matches[key].toString();
	        /* Is tag not in allowed list? Remove from str!*/
	        allowed = false;

	        /* Go through all allowed tags*/
	        for (k in allowed_array) {            /* Init*/
	            allowed_tag = allowed_array[k];
	            i = -1;

	            if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+'>');}
	            if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+' ');}
	            if (i != 0) { i = html.toLowerCase().indexOf('</'+allowed_tag)   ;}

	            /* Determine*/
	            if (i == 0) {                allowed = true;
	                break;
	            }
	        }
	        if (!allowed) {
	            str = replacer(html, "", str); /* Custom replace. No regexing*/
	        }
	    }
	    return str;
	}
